import * as React from "react";
import Heading from "../../../ui/Heading/Heading";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";

interface IProps {
  templateList: any;
  handleLabelClick: any;
  handleTemplateClick: any;
}

const TemplateSelector: React.FC<IProps> = (props): JSX.Element => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const searchTemplateInputRef = React.useRef<HTMLInputElement>(null);
  const [showDropdown, setShowDropdown] = React.useState(false)

  const { labels, labelTemplateMap } = React.useMemo(() => {
    const labelTemplateMap = props.templateList.reduce((a: any, c: any) => {
      if (c.TemplateLabels?.length) {
        a = c.TemplateLabels.reduce((acc: any, curr: any) => {
          if (!acc[curr.Label.id])
            acc[curr.Label.id] = {
              id: curr.Label.id,
              name: curr.Label.title,
              children: {},
            };
          if (!acc[curr.Label.id].children[c.id])
            acc[curr.Label.id].children[c.id] = { id: c.id, name: c.name };
          return acc;
        }, a);
      }
      return a;
    }, {});

    const labels = Object.values(labelTemplateMap).map((item: any) => ({
      id: item.id,
      name: item.name,
    }));

    return {
      labels,
      labelTemplateMap,
    };
  }, [props.templateList]);

  const sortedTemplateList = React.useMemo(() => {
    return [...(props?.templateList || [])]
      .sort((a, b) => a.id - b.id)
      .filter(
        (template) =>
          (template.name ?? "")
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          template.id.toString().includes(searchTerm)
      );
  }, [props.templateList, searchTerm]);

  React.useEffect(() => {
    if(showDropdown)
    searchTemplateInputRef.current?.focus()
  }, [showDropdown]);

    const handleTemplateSelect = (templateId: number) => {
    props.handleTemplateClick(templateId)
    setSearchTerm("")
    setShowDropdown(true)
    setTimeout(() => {
      searchTemplateInputRef.current?.focus()
    }, 0)
  }

  const handleDropdownToggle = (isOpen:boolean) =>{
    setSearchTerm("")
    setShowDropdown(isOpen)
  }
    
  return (
    <>
      <Heading headingTitle="Templates" wrapperClassName="mb-2" />
      {/* <select
        className="form-select mb-3"
        title={"Select Template"}
        onChange={(e) => props.handleTemplateClick(Number(e.target.value))}
        value={""}
        name={"template"}
      >
        {
          <option value="" disabled selected>
            Select template
          </option>
        }
        {React.Children.toArray(
          sortedTemplateList.map((el: any) => (
            <option key={el.id} value={el.id}>
              {el.name} (ID - {el.id})
            </option>
          ))
        )}
      </select> */}

      <DropdownButton
        as={ButtonGroup}
        variant="primary"
        className="dropdown-select"
        show={showDropdown}
        onToggle={handleDropdownToggle}
        id={`template-select`}
        title={
          <>
            Select Template
            <i
              className="fa-solid fa-chevron-down"
              style={{ fontSize: "12px" }}
            ></i>
          </>
        }
      >
        <div className="p-3 position-sticky top-0 left-0 bg-white">
          <input
            className="form-control"
            placeholder="Search..."
            ref={searchTemplateInputRef}
            value={searchTerm}
            autoFocus={true}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {React.Children.toArray(
          sortedTemplateList.map((el: any) => (
            <Dropdown.Item
              key={el.id}
              eventKey={el.id}
              onClick={() => handleTemplateSelect(Number(el.id))}
            >
              {el.name} (ID - {el.id})
            </Dropdown.Item>
          ))
        )}
      </DropdownButton>

      <Heading headingTitle="Labels" wrapperClassName="mb-2" />
      <select
        className="form-select mb-3"
        title={"Select Label"}
        onChange={(e) =>
          props.handleLabelClick(labelTemplateMap[e.target.value]?.children)
        }
        value={""}
        name={"label"}
      >
        {
          <option value="" disabled selected>
            Select label
          </option>
        }
        {React.Children.toArray(
          labels.map((el: any) => (
            <option key={el.id} value={el.id}>
              {el.name}
            </option>
          ))
        )}
      </select>
    </>
  );
};

export default TemplateSelector;
