import { ColDef } from "ag-grid-community";
const ROLE_MAP = { SA: 'Super Admin', PA : 'Parent Admin', SP: 'Salse Person', CP: 'Contact Person' } as {[key: string]: string}
export const columnDefs: ColDef[] = [
    {
        field: "name",
        headerName: "Name",
        width: 70,
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true
    },
    {
        field: "email",
        headerName: "Email",
        width: 110,
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true

    },
    {
        field: "role",
        headerName: "Role",
        width: 50,
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true
    },
    {
        field: "account",
        headerName: "Account",
        width: 70,
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true
    },
    {
        field: "createdAt",
        headerName: "Created",
        width: 70,
        sortable: true,
        valueFormatter: (params) => {
            return params.value ? new Date(params.value).toLocaleString() : '---'
        },
        // filter: 'agDateColumnFilter',
        // floatingFilter: true
    },
    {
        field: "lastLoginAt",
        headerName: "Last Login",
        width: 70,
        sortable: true,
        sort: 'desc',
        valueFormatter: (params) => {
            return params.value ? new Date(params.value).toLocaleString() : '---'
        },
        // filter: 'agDateColumnFilter',
        // floatingFilter: true,
    },
]

export const transformUserList = (data: any) => {
    return data.map((item: any) => ({
        ...item,
        role: ROLE_MAP[item.role],
        createdAt: new Date(item.createdAt),
        lastLoginAt: item.lastLoginAt ? new Date(item.lastLoginAt) : null,
        ...((item.role === 'SA' || item.role === 'CP') && { account: '---'}),
        ...((item.role === 'PA') && { account: item?.parentAccount?.accountName || '---' }),
        ...((item.role === 'SP') && { account: item?.salesPeople?.length ? item?.salesPeople?.length : '--' }),
    })) 

}