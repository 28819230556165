import {
  addBlankGarment,
  enableOrDisableLocalBlankGarmentInventory,
  fetchBlankGarmentInventory,
  fetchBlankGarments,
  fetchLocalBlankGarmentInventory,
} from "api/inventoryService";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import { toast } from 'react-toastify';

const SIZE_LIST = ["XS", "S", "M", "L", "XL", "XXL"];

type ACTION = "Add" | "View";

type InventoryModalType = {
  open: boolean;
  closeModal: () => void;
  accessToken: string;
  colorMap: any;
  action: ACTION;
  selectedId: number | null;
  fetchBlankGarments: Function;
  garmentMap: any;
};

type errorMsgType = {
  productId?: string | null;
  other?: string | null;
};

type TemplateType = {
  productId: number;
  styleNumber: string;
  description: string;
};

const InventoryModal: FC<InventoryModalType> = (props): JSX.Element => {
  const {
    open,
    closeModal,
    colorMap,
    action,
    selectedId,
    fetchBlankGarments: refetchBlankGarments,
    garmentMap,
  } = props;
  const [blankTemplates, setBlankTemplates] = useState<TemplateType[]>([]);
  const [sortedColor, setSortedColor] = useState<string[]>([]);
  const [productId, setProductId] = useState<number | null>(null);
  const [inventory, setInventory] = useState<any>({});
  const [errorMsg, setErrorMsg] = useState<errorMsgType>({});
  const [isLoading, setIsLoading] = useState(false);

  const [isChanged, setIsChanged] = useState(false);
  const [sizeEnabled, setSizeEnabled] = useState<any>([]);
  const [colorEnabled, setColorEnabled] = useState<any>([]);
  const [sizeDisabled, setSizeDisabled] = useState<any>([]);
  const [colorDisabled, setColorDisabled] = useState<any>([]);

  const toggleEnableDisable = (
    type: "size" | "color",
    item: string,
    checked: boolean
  ) => {
    console.log(type, item, checked);
    setIsChanged(true);
    if (type === "size") {
      setSizeEnabled((prev: any) => {
        const _prev = prev.slice();
        if (checked) {
          _prev.push(item);
        } else {
          _prev.splice(prev.indexOf(item), 1);
        }
        return _prev;
      });

      setSizeDisabled((prev: any) => {
        const _prev = prev.slice();
        if (!checked) {
          _prev.push(item);
        } else {
          _prev.splice(prev.indexOf(item), 1);
        }
        return _prev;
      });
    } else {
      setColorEnabled((prev: any) => {
        const _prev = prev.slice();
        if (checked) {
          _prev.push(item);
        } else {
          _prev.splice(prev.indexOf(item), 1);
        }
        return _prev;
      });

      setColorDisabled((prev: any) => {
        const _prev = prev.slice();
        if (!checked) {
          _prev.push(item);
        } else {
          _prev.splice(prev.indexOf(item), 1);
        }
        return _prev;
      });
    }
  };

  // console.log(sizeEnabled, colorEnabled, sizeDisabled, colorDisabled);

  const handleChange = (
    e:
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    let errors = { ...errorMsg };
    switch (name) {
      case "productId":
        errors.productId = Number.isNaN(parseInt(value))
          ? "Template must be selected"
          : null;
        break;
      default:
        break;
    }

    setErrorMsg(errors);
    setProductId(parseInt(value));
  };

  const handleUpdateInventory = async () => {
    setIsLoading(true);
    if (sizeEnabled.length <= 0 || colorEnabled.length <= 0) {
      toast.error('Please select at least one Color and one Size.');
      setIsLoading(false);
      setIsChanged(false);
      return
    }
    await enableOrDisableLocalBlankGarmentInventory(productId, {
      enabledSizes: sizeEnabled,
      enabledColors: colorEnabled,
      disabledSizes: sizeDisabled,
      disabledColors: colorDisabled,
    });
    closeModal();
    setIsLoading(false);
    setIsChanged(false);

  };

  const handleSubmit = async () => {
    const blankGarment = blankTemplates.find(
      (item) => item.productId == productId
    );
    try {
      // eslint-disable-next-line no-throw-literal
      if (!blankGarment) throw "Please select Garment First";
      setIsLoading(true);
      await addBlankGarment(blankGarment, props.accessToken);
      refetchBlankGarments();
      closeModal();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    async function _setBlankTemplates() {
      try {
        setIsLoading(true);
        const result = await fetchBlankGarments(props.accessToken);
        if (!result?.data?.data?.length) {
          setErrorMsg({ productId: "Blank Garments Not Found" });
        }
        if (action === "View") {
          const temp = result.data.data.find(
            (item: any) => item.productId == selectedId
          );
          if (temp) setProductId(selectedId);
          fetchInventory(selectedId);
        }
        let _blankTemplates = result.data.data.filter((item: any) => {
          if (action === "Add") {
            return !garmentMap[item.productId];
          }
          return true;
        });
        setBlankTemplates(_blankTemplates);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setErrorMsg({ productId: "Something Went Wrong" });
        setIsLoading(false);
      }
    }
    _setBlankTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.accessToken]);

  const getEnabledColorsAndSizes = (data: any) => {
    const enabledColors = new Set<string>();
    const enabledSizes = new Set<string>();

    Object.keys(data).forEach((color) => {
      const sizes = data[color];

      Object.keys(sizes).forEach((size) => {
        if (sizes[size].enabled) {
          enabledColors.add(color);
          enabledSizes.add(size);
        }
      });
    });

    return {
      enabledColors: Array.from(enabledColors).sort(),
      enabledSizes: Array.from(enabledSizes).sort(),
    };
  };

  const fetchInventory = async (productId: any) => {
    try {
      setIsLoading(true);
      let result: any = null;
      if (action === "Add") {
        result = await fetchBlankGarmentInventory(productId, props.accessToken);
      } else {
        result = await fetchLocalBlankGarmentInventory(
          productId,
          props.accessToken
        );
      }
      if (!result?.data?.data) {
        setErrorMsg({ other: "Failed to fetch inventory" });
      }
      const _sortedColors = Object.keys(result.data.data).sort(
        (a: string, b: string) => a.localeCompare(b)
      );
      setSortedColor(_sortedColors);
      setInventory(result.data.data);
      const _inventory = result.data.data;

      const { enabledColors, enabledSizes } =
        getEnabledColorsAndSizes(_inventory);
      setSizeEnabled(enabledSizes);
      setColorEnabled(enabledColors);
      setSizeDisabled(SIZE_LIST.filter((size) => !enabledSizes.includes(size)));
      setColorDisabled(
        _sortedColors.filter((color) => !enabledColors.includes(color))
      );

      setIsLoading(false);
    } catch (err) {
      setErrorMsg({ other: "Something Went Wrong" });
      setIsLoading(false);
    }
  };

  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={open}
      onHide={closeModal}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={"lg"}
    >
      <Modal.Header>
        <div className="row">
          <div className="col-auto">
            <h2 className="heading4-bold mb-0">
              {action === "Add"
                ? "Add New Blank Garment"
                : "View Blank Garment Inventory"}
            </h2>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <>
          <p className="mb-2">Blank Garment Name</p>
          <div className="row">
            <div className="col-lg-8">
              <select
                name={"productId"}
                defaultValue={""}
                onChange={handleChange}
                className="mb-3 form-select"
                title={"Select Style"}
                disabled={action === "View"}
              >
                <option disabled value={""} selected={!productId}>
                  Select
                </option>
                {blankTemplates.map((el) => (
                  <option
                    key={el.styleNumber}
                    value={el.productId}
                    selected={productId == el.productId}
                  >
                    {el.styleNumber} - {el.description}
                  </option>
                ))}
              </select>
            </div>
            <div
              className="col-lg-4"
              style={{ display: action === "Add" ? "block" : "none" }}
            >
              <button
                className="btn btn-outline-danger px-4 ms-3"
                type="button"
                disabled={!productId}
                onClick={() => fetchInventory(productId)}
              >
                {" "}
                Fetch Inventory
              </button>
            </div>
          </div>
          <div>
            {isLoading && <SpinnerLoader message={"Loading..."} />}
            <table
              className="table"
              style={{
                visibility: Object.keys(inventory).length
                  ? "visible"
                  : "hidden",
              }}
            >
              <tr>
                <th>Color</th>
                {SIZE_LIST.map((head) => (
                  <th>
                    {head}{" "}
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="check-all"
                      name="check-all"
                      onChange={() => {
                        toggleEnableDisable(
                          "size",
                          head,
                          sizeEnabled.indexOf(head) == -1
                        );
                      }}
                      checked={sizeEnabled.indexOf(head) != -1}
                    />
                  </th>
                ))}
              </tr>

              {sortedColor.map((color) => {
                return (
                  <tr>
                    <td>
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id="check-all"
                        name="check-all"
                        onChange={() => {
                          toggleEnableDisable(
                            "color",
                            color,
                            colorEnabled.indexOf(color) == -1
                          );
                        }}
                        checked={colorEnabled.indexOf(color) != -1}
                      />
                      {color}
                    </td>
                    {SIZE_LIST.map((size) => {
                      const data = inventory?.[color]?.[size] || {};
                      return (
                        <td
                          style={{
                            color:
                              colorEnabled.includes(color) &&
                              sizeEnabled.includes(size)
                                ? "black"
                                : "grey",
                          }}
                        >
                          {parseInt(data?.qtyInventory)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </table>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div className="me-3">
          <button
            className="btn btn-danger px-4 me-3"
            onClick={handleSubmit}
            style={action === "View" ? { display: "none" } : {}}
            disabled={!productId || isLoading}
          >
            Save
          </button>
          <button
            className="btn btn-danger px-4 me-3"
            onClick={handleUpdateInventory}
            style={action !== "View" ? { display: "none" } : {}}
            disabled={!isChanged}
          >
            Update
          </button>
          <button className="btn btn-outline-danger px-4" onClick={closeModal}>
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default InventoryModal;
